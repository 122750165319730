import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AppCrmSyncLog,
  AppCrmSyncLogSearchParams,
  AppCrmSyncStats,
  AppCrmSyncToggleResponse,
  AppCrmUser,
  AppLatestSyncStatus,
  AppTemplateList,
  FelloApiSuccessfulResponse,
  FelloConnectedApp,
  FelloConnectedAppType,
  InviteTeamMember,
  PagedResult
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";

@Injectable({providedIn: "root"})
export class AgentConnectedAppsApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/apps`;
  }

  getAllApps(): Observable<AppTemplateList[]> {
    return this.http.get<AppTemplateList[]>(`${this.baseUrl}/templates/search`);
  }

  getAppById<AppType extends FelloConnectedApp = FelloConnectedApp>(id: string): Observable<AppType> {
    return this.http.get<AppType>(`${this.baseUrl}/${id}`);
  }

  getAppOwnerInfo(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}/crm/owner-info`);
  }

  getAllConnectedApps(): Observable<FelloConnectedApp[]> {
    return this.http.get<FelloConnectedApp[]>(`${this.baseUrl}/search`);
  }

  disconnectApp(appId: string): Observable<Record<string, boolean | null>> {
    return this.http.post<Record<string, boolean | null>>(`${this.baseUrl}/${appId}/disconnect`, {});
  }

  verifyKey(crm: string, response: Record<string, string>): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/crm/${crm}/key/verify`, response);
  }

  connectKey(crm: string, response: Record<string, string>): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/crm/${crm}/connect`, response);
  }

  connectApp(app: string, response: Record<string, string>): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${app}/connect`, response);
  }

  getFilterConfig(crm: string) {
    return this.http.get<any>(`${this.baseUrl}/crm/${crm}/filter-config`);
  }

  getSyncRules(appId: string) {
    return this.http.get<any>(`${this.baseUrl}/${appId}/crm/sync/rules`);
  }

  saveAndSyncConfig(appId: string, response: any) {
    return this.http.post<any>(`${this.baseUrl}/${appId}/crm/config/save`, response);
  }

  getCrmFields(
    appId: string,
    crm: string,
    field: "name" | "source" | "stages" | "users" | "lead-status" | "lead-sources" | "lead-tags" | "contact-status"
  ): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/${appId}/crm/${crm}/${field}`);
  }

  getSyncStats(appId: string): Observable<AppCrmSyncStats> {
    return this.http.get<AppCrmSyncStats>(`${this.baseUrl}/${appId}/crm/sync/stats`);
  }

  getLatestSyncStatus(appId: string): Observable<AppLatestSyncStatus | null> {
    return this.http.get<AppLatestSyncStatus | null>(`${this.baseUrl}/${appId}/crm/sync/latest`);
  }

  getCrmUsers(appId: string): Observable<AppCrmUser[]> {
    return this.http.get<AppCrmUser[]>(`${this.baseUrl}/${appId}/crm/users`);
  }

  updateCrmUser(appId: string, entryId: string, response: AppCrmUser): Observable<any> {
    return this.http.patch<AppCrmUser[]>(`${this.baseUrl}/${appId}/crm/users/${entryId}`, response);
  }

  toggleSyncState(appId: string, response: AppCrmSyncToggleResponse) {
    return this.http.post<AppCrmSyncToggleResponse>(`${this.baseUrl}/${appId}/crm/sync/toggle`, response);
  }

  inviteTeamMembers(appId: string, response: InviteTeamMember): Observable<FelloApiSuccessfulResponse<null>> {
    return this.http.post<FelloApiSuccessfulResponse<null>>(`${this.baseUrl}/${appId}/crm/invite-users`, response);
  }

  userRefresh(appId: string): Observable<FelloApiSuccessfulResponse<null>> {
    return this.http.post<FelloApiSuccessfulResponse<null>>(`${this.baseUrl}/${appId}/crm/users/refresh`, {});
  }

  fetchSyncReport(appId: string, filters: AppCrmSyncLogSearchParams): Observable<PagedResult<AppCrmSyncLog>> {
    return this.http.get<PagedResult<AppCrmSyncLog>>(`${this.baseUrl}/${appId}/crm/sync/report/search`, {params: filters as HttpParams});
  }

  checkIfPrivateAppExists(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/private/exists`);
  }

  createPrivateApp(title: string, description: string): Observable<FelloConnectedApp<FelloConnectedAppType.PRIVATE>> {
    return this.http.post<FelloConnectedApp<FelloConnectedAppType.PRIVATE>>(`${this.baseUrl}/private/create`, {
      title,
      description
    });
  }

  savePrivateApp(appId: string, title: string, description: string): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/private/${appId}/save`, {title, description});
  }
}
