import {ActionFieldExprValueType, FQLOrExpr, PaginationParams} from "../mvc";
import {AccessRoleType} from "./Agent";
import {ChangelogEventType} from "../deal";

export enum FieldDirections {
  IN = "IN",
  OUT = "OUT",
  BI = "BI"
}

export enum FelloConnectedAppTemplateStatus {
  COMING_SOON = "COMING_SOON",
  AVAILABLE = "AVAILABLE",
  CONNECTED = "CONNECTED"
}

export enum FelloConnectAppStatus {
  SETUP_PENDING = "SETUP_PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INACTIVE_AUTH_FAIL = "INACTIVE_AUTH_FAIL"
}

export enum FelloConnectedAppType {
  FUB = "FUB",
  PRIVATE = "PRIVATE",
  SIERRA = "SIERRA",
  KVCORE = "KVCORE",
  LOFTY = "LOFTY",
  BRIVITY = "BRIVITY",
  CINC = "CINC",
  HOMEWARD = "HOMEWARD",
  ZESTIMATE = "ZESTIMATE",
  BOLDTRAIL = "BOLDTRAIL"
}

export enum AppCrmFieldMappingDirection {
  OUT = "OUT",
  IN = "IN",
  BI = "BI"
}

export interface AppCrmFubConfiguration {
  direction: AppCrmFieldMappingDirection;
  // TODO: update them as per usage
  mapping: any;
  inboundSyncFilter?: any;
  outboundSyncFilter?: any;
  inboundApplyMode: any;
  setupStep: any;
  events: any[];
}

export interface AppPrivateAppConfig {
  key: string;
}

export type FelloConnectedAppTypeToSettingsMapType = {
  [FelloConnectedAppType.FUB]: AppCrmFubConfiguration;
  [FelloConnectedAppType.SIERRA]: AppCrmFubConfiguration;
  [FelloConnectedAppType.BOLDTRAIL]: AppCrmFubConfiguration;
  [FelloConnectedAppType.PRIVATE]: AppPrivateAppConfig;
  [FelloConnectedAppType.HOMEWARD]: Record<string, never>;
  [FelloConnectedAppType.KVCORE]: never;
  [FelloConnectedAppType.LOFTY]: never;
  [FelloConnectedAppType.BRIVITY]: never;
  [FelloConnectedAppType.CINC]: never;
  [FelloConnectedAppType.ZESTIMATE]: never;
};
export type AvailableFelloConnectedApp = keyof FelloConnectedAppTypeToSettingsMapType;
export type FelloConnectAppConfigType<LayoutType extends AvailableFelloConnectedApp> = FelloConnectedAppTypeToSettingsMapType[LayoutType];

export enum AppCategory {
  CRM = "CRM",
  DATA_AND_ANALYTICS = "Data and Analytics",
  SELLING_SOLUTIONS = "Selling Solutions"
}

export const ConnectedAppType: Partial<Record<FelloConnectedAppType, AppCategory>> = {
  [FelloConnectedAppType.FUB]: AppCategory.CRM,
  [FelloConnectedAppType.SIERRA]: AppCategory.CRM,
  [FelloConnectedAppType.KVCORE]: AppCategory.CRM,
  [FelloConnectedAppType.LOFTY]: AppCategory.CRM,
  [FelloConnectedAppType.BRIVITY]: AppCategory.CRM,
  [FelloConnectedAppType.CINC]: AppCategory.CRM,
  [FelloConnectedAppType.BOLDTRAIL]: AppCategory.CRM,
  [FelloConnectedAppType.HOMEWARD]: AppCategory.SELLING_SOLUTIONS,
  [FelloConnectedAppType.ZESTIMATE]: AppCategory.DATA_AND_ANALYTICS
};

export const SellingSolutionDocumentationLinks: Partial<Record<FelloConnectedAppType, string>> = {
  [FelloConnectedAppType.HOMEWARD]: "https://hifello.com/knowledge/homeward"
};

export const DataAndAnalyticsDocumentationLinks: Partial<Record<FelloConnectedAppType, string>> = {
  [FelloConnectedAppType.ZESTIMATE]: "https://hifello.com/knowledge/fello-zestimate-by-zillow-integration"
};

export interface FelloConnectedApp<AppType extends AvailableFelloConnectedApp = AvailableFelloConnectedApp> {
  _id: FelloConnectedAppType;
  agencyId: string;
  title: string;
  description: string;
  templateDedupeId: string;
  status: FelloConnectAppStatus;
  type: AppType;
  // eslint-disable-next-line
  // @ts-ignore
  config: FelloConnectAppConfigType<AppType>;
  startedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export class AppTemplate {
  _id: FelloConnectedAppType;
  title: string;
  description: string;
  logo: string; // TODO: discuss MediaType
  status: FelloConnectedAppTemplateStatus;
  app?: Omit<FelloConnectedApp, "_id" & "agencyId" & "createdAt" & "updatedAt" & "startedAt">;
}

export interface AppTemplateList {
  linkedAppId?: string;
  template: AppTemplate;
}

export interface AppCrmUser {
  _id: string;
  crmUserId: string;
  crmMeta: {
    role: string;
    status: string;
  };
  imageUrl: string;
  emailId: string;
  appId: string;
  agencyId: string;
  fullName: string;
  createdAt: string;
  updatedAt: string;
  felloAgentId: string;
  isCrmAccountOwner: boolean;
}

export enum AppCrmBatchSyncRequestStatus {
  QUEUED = "QUEUED",
  IN_JOB_CREATION = "IN_JOB_CREATION",
  IN_SYNC = "IN_SYNC",
  OUT_JOB_CREATION = "OUT_JOB_CREATION",
  OUT_SYNC = "OUT_SYNC",
  COMPLETED = "COMPLETED"
}

export interface AppLatestSyncStatus {
  _id: string;
  appId: string;
  agencyId: string;
  status: AppCrmBatchSyncRequestStatus;
  createdAt: string;
  updatedAt: string;
}

export interface AppCrmSyncStats {
  inSyncCount: number;
  failingCount: number;
}

export enum AppCrmSyncToggleState {
  TURN_OFF = "TURN_OFF",
  TURN_ON = "TURN_ON"
}

export interface AppCrmSyncToggleResponse {
  action: AppCrmSyncToggleState;
}

export enum AppCrmSyncLogStatus {
  PENDING = "PENDING",
  IN_SYNC = "IN_SYNC",
  FAILED = "FAILED"
}

export interface AppCrmSyncLogSearchParams extends PaginationParams {
  search?: string;
  status?: AppCrmSyncLogStatus;
}

export enum AppCrmSyncLogFailureReason {
  DELETED_IN_CRM = "DELETED_IN_CRM",
  DELETED_IN_FELLO = "DELETED_IN_FELLO",
  DUPLICATE_IN_CRM = "DUPLICATE_IN_CRM",
  PRIMARY_EMAIL_DUP = "PRIMARY_EMAIL_DUP"
}

export interface AppCrmSyncLog {
  appId: string;
  name: string;
  emailId?: string;
  contactId?: string;
  crmId?: string;
  status: AppCrmSyncLogStatus;
  failureReason?: AppCrmSyncLogFailureReason | string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface InviteTeamMember {
  invitedMemberEmails: string[];
  invitedMemberRole: AccessRoleType;
  sendInviteEmail: boolean;
}

export enum AppCrmEventsActionType {
  UPDATE_FIELDS_IN_FUB = "UPDATE_FIELDS_IN_FUB",
  UPDATE_FIELDS_IN_SIERRA = "UPDATE_FIELDS_IN_SIERRA",
  UPDATE_LEADS_IN_SIERRA = "UPDATE_LEADS_IN_SIERRA",
  ADD_NOTE_IN_SIERRA = "ADD_NOTE_IN_SIERRA",
  SELLER_INQUIRY_IN_FUB = "SELLER_INQUIRY_IN_FUB",
  UPDATE_FIELDS_IN_BOLDTRAIL = "UPDATE_FIELDS_IN_BOLDTRAIL",
  ADD_NOTE_IN_BOLDTRAIL = "ADD_NOTE_IN_BOLDTRAIL"
}

export interface AppCrmEventsAction {
  actionType: AppCrmEventsActionType;
  action: AppCrmUpdateFieldsInFubAction;
}

export enum AppCrmUpdateFieldsInFubFieldValueTemplateType {
  FELLO_ORIGIN = "FELLO_ORIGIN",
  FORM_SUMMARY = "FORM_SUMMARY",
  FORM_SELLING_TIMELINE = "FORM_SELLING_TIMELINE",
  LEAD_TYPE = "LEAD_TYPE"
}

export enum AppCrmUpdateFieldsInFubFieldType {
  STAGE = "STAGE",
  SOURCE = "SOURCE",
  EVENT_MESSAGE = "EVENT_MESSAGE",
  FORM_SELLING_TIMELINE = "FORM_SELLING_TIMELINE",
  DEAL_TYPE = "DEAL_TYPE"
}

export class AppCrmUpdateFieldsInFubActionEntry {
  fieldType: AppCrmUpdateFieldsInFubFieldType;
  valueType: ActionFieldExprValueType;
  value?: string;
}

export class AppCrmUpdateFieldsInFubAction {
  entries: AppCrmUpdateFieldsInFubActionEntry[];
}

export enum AppCrmEventsTriggerType {
  FELLO_EVENT = "FELLO_EVENT"
}

export interface AppConfigurationEventsRuleUpdateFieldsActionFieldEntry {
  fieldLabel: string;
  fieldType: AppCrmUpdateFieldsInFubFieldType;
  valueType: ActionFieldExprValueType;
  templates?: {
    template: AppCrmUpdateFieldsInFubFieldValueTemplateType;
    label: string;
  }[];
  lookUp?: string;
}

export interface AppConfigurationEventsRuleAllowedActionEntryData {
  fields: AppConfigurationEventsRuleUpdateFieldsActionFieldEntry[];
}

export interface AppCrmConfigurationEventsRuleAllowedActionEntry {
  actionType: AppCrmEventsActionType;
  data: AppConfigurationEventsRuleAllowedActionEntryData;
}

export interface AppCrmEvents {
  enabled: boolean;
  direction: FieldDirections;
  triggerType: AppCrmEventsTriggerType;
  felloEventType?: ChangelogEventType;
  filterFql?: FQLOrExpr;
  action?: AppCrmEventsAction;
}

export interface AppCrmConfigurationEventsRules {
  triggerType: AppCrmEventsTriggerType;
  direction: FieldDirections;
  felloEventType?: ChangelogEventType;
  fqlFilterConfig: "CONTACT";
  actions: AppCrmConfigurationEventsRuleAllowedActionEntry[];
}
