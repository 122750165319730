<ng-container *ngIf="settings">
  <div class="wrapper">
    <div class="dialog" [libCustomTheme]="settings.theme">
      <ng-container *ngIf="currentStep === AddAddressSteps.Address_Entry">
        <ng-container *ngTemplateOutlet="addressEntry"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentStep === AddAddressSteps.Address_Confirmation">
        <ng-container *ngTemplateOutlet="confirmAddress"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentStep === AddAddressSteps.Contact_Details">
        <ng-container *ngTemplateOutlet="contactDetails"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #addressEntry>
    <p class="text-size-22 fw-700 mb-2">Welcome!</p>
    <p class="text-size-16 fw-400 mb-2 mb-4">Let's start by confirming your address to unlock your personalized Home Value Dashboard.</p>

    <div class="form-field">
      <label class="mb-2 text-size-16 fw-600">Home Address</label>
      <lib-smarty-address-auto-complete
        [(ngModel)]="address"
        name="propertyAddress"
        id="propertyAddress"
        #propertyAddress="ngModel"
        placeholder="Search for address"
        maxlength="100"
        (ngModelChange)="addrComp = null; image = null"
        [disabled]="isLoading"
        [required]="true"
      ></lib-smarty-address-auto-complete>
    </div>

    <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{height: '45px', width: '100%'}"></ngx-skeleton-loader>
    <div class="flex-box-center mt-3">
      <button (click)="validateAddress()" [disabled]="address.length <= 0 || isLoading" class="ml-auto">Continue</button>
    </div>
  </ng-template>
  <ng-template #confirmAddress>
    <p class="text-size-22 fw-700 mb-2">Welcome!</p>
    <p class="text-size-16 fw-400 mb-2 mb-4">Let's start by confirming your address to unlock your personalized Home Value Dashboard.</p>
    <div class="address-c mt-3" *ngIf="addrComp && !isLoading">
      <ng-container>
        <div class="flex-box-center">
          <img [src]="image ?? '/assets/images/svg/no_property_image.svg'" alt="house-img" class="house-img" />
          <div>
            <p class="text-size-18 fw-500">{{ addrComp.doorNumber }} {{ addrComp.street }}</p>
            <p class="text-size-14 fw-400 mt-1">{{ addrComp.city }}, {{ addrComp.state }} {{ addrComp.zip }}</p>
          </div>
        </div>
        <a class="link flex-box-center c-pointer" (click)="currentStep = AddAddressSteps.Address_Entry">Edit</a>
      </ng-container>
    </div>
    <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{height: '45px', width: '100%'}"></ngx-skeleton-loader>
    <div class="flex-box-center mt-3">
      <button (click)="addAddress()" [disabled]="address.length <= 0 || isLoading" class="ml-auto">Continue</button>
    </div>
  </ng-template>

  <ng-template #contactDetails>
    <p class="text-size-22 fw-700 mb-2">Last step: Confirm Your Details</p>
    <p class="text-size-16 fw-400 mb-2 mb-4">Complete your information to access to your personalized Home Value Dashboard.</p>

    <form #form="ngForm">
      <div class="flex-box-gap-2 flex-box-direction-column flex-box-lg-direction-row">
        <div class="form-field mb-3">
          <label class="mb-1 d-block"
            >First Name <span class="fw-400" *ngIf="!settings.hvFormSettings.firstNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="First Name"
            [(ngModel)]="homeValueFromDashboardRequest.firstName"
            [minlength]="2"
            [maxLength]="64"
            name="firstName"
            [required]="settings.hvFormSettings.firstNameMandatory"
            #firstNameField="ngModel"
          />
          <div *ngIf="firstNameField.invalid && firstNameField.touched" class="error-field">
            <div *ngIf="firstNameField.errors!">Enter a valid first name.</div>
          </div>
        </div>
        <div class="form-field mb-3">
          <label class="mb-1 d-block"
            >Last Name <span class="fw-400" *ngIf="!settings.hvFormSettings.lastNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="Last Name"
            [(ngModel)]="homeValueFromDashboardRequest.lastName"
            [maxLength]="64"
            [minlength]="1"
            [required]="settings.hvFormSettings.lastNameMandatory"
            #lastNameInput="ngModel"
            name="lastName"
          />
          <div *ngIf="lastNameInput.invalid && lastNameInput.touched" class="error-field">
            <div *ngIf="lastNameInput.errors!">Enter a valid last name.</div>
          </div>
        </div>
      </div>
      <div class="flex-box-gap-2 flex-box-direction-column flex-box-lg-direction-row">
        <div class="form-field mb-3">
          <label class="mb-1 d-block">Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            class="disabled-grey"
            disabled
            [value]="homeValueFromDashboardRequest.emailId"
          />
        </div>
        <div class="form-field mb-3">
          <label class="mb-1 d-block"> Phone <span class="fw-400" *ngIf="!settings.hvFormSettings.phoneMandatory">(Optional)</span></label>
          <input
            type="tel"
            placeholder="Phone number"
            name="phone"
            libFormatPhone
            [required]="settings.hvFormSettings.phoneMandatory"
            [pattern]="phoneNumberPattern"
            [(ngModel)]="homeValueFromDashboardRequest.phone"
            #phone="ngModel"
          />
          <div *ngIf="phone.invalid && phone.touched" class="error-field">
            <div *ngIf="phone.errors!">Enter a valid phone.</div>
          </div>
        </div>
      </div>

      <div class="flex-box-direction-row" *ngIf="settings.hvFormSettings?.includeSellingTimeline">
        <div class="form-field w-100 mb-4">
          <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
          <div class="flex-box-direction-column flex-box-gap-2">
            <div class="flex-box-center">
              <input
                type="radio"
                id="option0"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.ASAP"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
                {{ SaleTimeline.ASAP | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option1"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.LT3M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
                {{ SaleTimeline.LT3M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option2"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B3TO6M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
                {{ SaleTimeline.B3TO6M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option3"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B6TO12M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
                {{ SaleTimeline.B6TO12M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option4"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.NA"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4">
                {{ SaleTimeline.NA | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}</label
              >
            </div>
          </div>
          <div *ngIf="saleTimeline.control.touched && saleTimeline.errors?.required" class="error-field text-size-12 text-color-red mt-1">
            <div>Please select an option.</div>
          </div>
        </div>
      </div>
      <p class="text-size-12 text-color-light-2">
        <ng-container *ngIf="settings.consentText">
          <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
        </ng-container>
        <ng-container *ngIf="!settings.consentText">
          By submitting your information, you consent to receive communications from {{ settings.name }} about services, including marketing
          and informational messages, via email, phone, and text. These may be sent using automated technology, such as autodialed calls,
          pre-recorded or artificial voice messages, and text messages. You can save a copy of these terms using your browser’s save or
          print function. Providing this consent is not a condition of purchasing goods or services.
        </ng-container>
      </p>
    </form>
    <ngx-spinner [showSpinner]="isLoading" [fullScreen]="true"></ngx-spinner>
    <div class="flex-box-center mt-3">
      <button (click)="currentStep = AddAddressSteps.Address_Confirmation" class="button-primary">Go Back</button>
      <button
        (click)="form.valid ? submitHomeValueForm() : form?.control?.markAllAsTouched(); scrollToError()"
        [disabled]="isLoading"
        class="ml-auto"
      >
        Continue
      </button>
    </div>
  </ng-template>
</ng-container>
