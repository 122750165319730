export enum MarketingAddonBillingUpdateType {
  DOWNGRADE = "DOWNGRADE",
  UPGRADE = "UPGRADE",
  NO_CHANGE = "NO_CHANGE"
}

export enum BillingUpdateType {
  ADDED = "ADDED",
  REMOVED = "REMOVED",
  UPDATED = "UPDATED",
  NO_CHANGE = "NO_CHANGE"
}

export enum CreditAddonUpdateType {
  UPGRADE = "UPGRADE",
  DOWNGRADE = "DOWNGRADE",
  REMOVE = "REMOVE",
  ADD = "ADD",
  NO_CHANGE = "NO_CHANGE",
  SWITCH_TO_SMALLER_PACK = "SWITCH_TO_SMALLER_PACK",
  SWITCH_TO_LARGER_PACK = "SWITCH_TO_LARGER_PACK"
}

export const BillingUpdateTypeLabel: Record<BillingUpdateType, string> = {
  [BillingUpdateType.ADDED]: "Added",
  [BillingUpdateType.REMOVED]: "Removed",
  [BillingUpdateType.UPDATED]: "Updated",
  [BillingUpdateType.NO_CHANGE]: ""
};
