import {BulkContactCreationBackground} from "../agent";

export enum BackgroundTaskType {
  CONTACT_IMPORT = "CONTACT_IMPORT",
  CONTACT_EXPORT = "CONTACT_EXPORT",
  LEAD_EXPORT = "LEAD_EXPORT",
  CHANGELOG_CONTACT_EXPORT = "CHANGELOG_CONTACT_EXPORT",
  HOME_VISITOR_VIEWS_EXPORT = "HOME_VISITOR_VIEWS_EXPORT",
  REAL_SELLERS_EXPORT = "REAL_SELLERS_EXPORT",
  REMOVE_TAGS_FROM_CONTACT = "REMOVE_TAGS_FROM_CONTACT",
  ADD_TAGS_TO_CONTACT = "ADD_TAGS_TO_CONTACT",
  UPDATE_CONTACT_MARKETING_STATUS = "UPDATE_CONTACT_MARKETING_STATUS",
  UPDATE_CONTACT_OWNER = "UPDATE_CONTACT_OWNER"
}

export enum BackgroundTaskStatus {
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  ABORTED = "ABORTED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}

export const BackgroundTaskStatusColor: Record<BackgroundTaskStatus, string> = {
  [BackgroundTaskStatus.QUEUED]: "orange",
  [BackgroundTaskStatus.RUNNING]: "orange",
  [BackgroundTaskStatus.ABORTED]: "red-light",
  [BackgroundTaskStatus.FAILED]: "red-light",
  [BackgroundTaskStatus.COMPLETED]: "green-light"
};

export const BackgroundTaskContactImportStatusLabel: Record<BackgroundTaskStatus, string> = {
  [BackgroundTaskStatus.QUEUED]: "processing",
  [BackgroundTaskStatus.RUNNING]: "processing",
  [BackgroundTaskStatus.ABORTED]: "aborted",
  [BackgroundTaskStatus.FAILED]: "import failed",
  [BackgroundTaskStatus.COMPLETED]: "import complete"
};

export const BackgroundTaskStatusLabel: Record<BackgroundTaskStatus, string> = {
  [BackgroundTaskStatus.QUEUED]: "processing",
  [BackgroundTaskStatus.RUNNING]: "processing",
  [BackgroundTaskStatus.ABORTED]: "cancelled",
  [BackgroundTaskStatus.FAILED]: "failed",
  [BackgroundTaskStatus.COMPLETED]: "ready for download"
};

export interface BackgroundTask<InputType extends BackgroundTaskInput, OutPutType extends BackgroundTaskOutput> {
  _id: string;
  rootTaskId?: string;
  parentTaskId?: string;
  input: InputType;
  output: OutPutType;
  type: BackgroundTaskType;
  status: BackgroundTaskStatus;
  level: number;
  agencyId: string;
  totalLeafs?: number;
  meta?: any;
  agentId?: string;
  createdAt: Date;
  updatedAt: Date;
}

export type BackgroundTaskLevel = number;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BackgroundTaskInput {}
export interface BackgroundTaskOutput {
  exception?: any;
  mediaId?: string;
  total?: number;
  failed?: number;
  success?: number;
  fileTitle?: string;
}
export interface LevelZeroContactImportInput extends BackgroundTaskOutput {
  contactCreationBackground: BulkContactCreationBackground;
}
export type LevelZeroContactImportOutput = BackgroundTaskOutput;

export type LevelZeroExportInput = BackgroundTaskOutput;
export type LevelZeroExportOutput = BackgroundTaskOutput;

export enum ChangelogContactExportType {
  POSTCARD_SCAN = "POSTCARD_SCAN",
  AUTOMATION_ENROLLED = "AUTOMATION_ENROLLED",
  AUTOMATION_ENGAGEMENT = "AUTOMATION_ENGAGEMENT",
  ENGAGE_HOME_VALUE_VIEW = "ENGAGE_HOME_VALUE_VIEW"
}
