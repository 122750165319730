import {AvmCondifence} from "../../deal";
import {DataProviderType} from "../../deal/enums/DataProviderType";
import {MlsStatus, MlsStatusDetailed, TransactionSource, TransactionStatus} from "../../property/enums/MlsStatus";
import {MediaWithType} from "../MediaWithType";
import {GeoPoint} from "../../geo";
import {invert} from "lodash-es";

export interface BaseAgentContactPropertyProfileData {
  updatedAt: Date;
}

export enum PropensityScoreAgentConfidence {
  VERY_HIGH = "VERY_HIGH",
  HIGH = "HIGH",
  MODERATE = "MODERATE",
  LOW = "LOW",
  VERY_LOW = "VERY_LOW"
}

export enum MortgageInterestType {
  FIXED = "FIXED",
  ADJUSTABLE = "ADJUSTABLE",
  VARIABLE = "VARIABLE",
  BALLON = "BALLON"
}

export const MortgageInterestTypeShorthandLabel: Record<MortgageInterestType, string> = {
  [MortgageInterestType.ADJUSTABLE]: "ARM",
  [MortgageInterestType.FIXED]: "FRM",
  [MortgageInterestType.VARIABLE]: "VRM",
  [MortgageInterestType.BALLON]: "BLN"
};

export enum MortgageLoanType {
  CDA = "Community Development Authority",
  CNV = "Conventional",
  FHA = "Federal Housing Administration",
  LM = "Leasehold Mortgage",
  PP = "Private Party",
  PVT = "Private Lender",
  SBA = "Small Business Administration",
  VA = "Veteran Affairs",
  WRP = "Wraparound Mortgage",
  A = "All Inclusive",
  B = "Blanket",
  C = "Credit Line",
  D = "Credit Line Deed of Trust",
  E = "Equity",
  L = "Balloon",
  N = "None",
  R = "Revolving Line of Credit",
  S = "Second/Subordinate Mortgage",
  T = "Third Mortgage/Deed of Trust",
  U = "Other Subordinate Loan",
  V = "Reverse"
}

export enum MortgageLoanTypeStandardized {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  HELOC = "HELOC"
}

export const MortgageLoanTypeStandardizedLabel: Record<MortgageLoanTypeStandardized, string> = {
  [MortgageLoanTypeStandardized.PRIMARY]: "Primary",
  [MortgageLoanTypeStandardized.SECONDARY]: "Secondary",
  [MortgageLoanTypeStandardized.HELOC]: "HELOC"
};

export const MortgageLoanTypeShorthandLabel: Record<string, string> = invert(MortgageLoanType);

export interface AgentContactPropertyMortgageWithSchedule {
  amount: number;
  monthlyPayment: number;
  loanDate: Date;
  term: number;
  interestRate: number;
  interestType?: string;
  interestTypeStandardized?: MortgageInterestType;
  remainingMortgageAmount: number;
  schedule: any;
}

export interface AgentContactPropertyMortgageProfile extends BaseAgentContactPropertyProfileData {
  mortgagePresent: boolean;
  numMortgages: number;
  interestRate?: number;
  remainingMortgageAmount: number;
  ltv?: number;
  loans?: AgentContactPropertyLoanEntry[];
  meta?: AgentContactPropertyMortgageWithSchedule[];
  provider: DataProviderType;
}

export interface AgentContactPropertyMortgageProfileWithMeta {
  profile: AgentContactPropertyMortgageProfile;
  meta?: {
    schedule?: AgentContactPropertyMortgageWithSchedule[];
  };
  equity?: number;
}

export interface AgentContactPropertyMlsProfile extends BaseAgentContactPropertyProfileData {
  status: MlsStatus;
  statusDetailed: MlsStatusDetailed;
  price: number;
  date: string;
  listingId?: string;
  statusChangeAt: string;
  provider: DataProviderType;
  expiredAt?: string;
  saleDate?: string;
  salePrice?: number;
  listDate?: string;
  listPrice?: number;
  images?: MediaWithType[];
  mlsOrg?: string;
  listingOfficeName?: string;
  listingOfficeAssociationName?: string;
  listingOfficeEmailId?: string;
  listingAgentName?: string;
  listingAgentEmailId?: string;
  listingAgentLicenseNumber?: string;
  daysOnMarket?: number;
}

export class AgentContactPropertyLoanEntry {
  loanAmount: number;
  loanDate: Date;
  interestRate: number;
  interestType?: string;
  interestTypeStandardized?: MortgageInterestType;
  loanTerm: number;
  loanType: MortgageLoanType;
  loanTypeStandardized: MortgageLoanTypeStandardized;
  currentBalance: number;
}

export interface AgentContactPropertyPropensityProfile extends BaseAgentContactPropertyProfileData {
  sellScore: number;
  sellScoreConfidence?: PropensityScoreAgentConfidence;
  provider: DataProviderType;
}

export interface AgentContactPropertyAvmProfile extends BaseAgentContactPropertyProfileData {
  median: number;
  high: number;
  low: number;
  confidenceScore: number;
  confidence: AvmCondifence;
  deviation: number;
  provider: DataProviderType;
}

export interface AgentContactPropertyProfileData {
  mls?: AgentContactPropertyMlsProfile;
  lastSaleAmount?: number;
  lastSaleDate?: string;
  lastTransactionDate?: string;
  lastTransactionPrice?: number;
  geo?: GeoPoint;
  lastTransactionStatus?: TransactionStatus;
  lastTransactionSource?: TransactionSource;
  mortgage?: AgentContactPropertyMortgageProfile;
  propensity?: AgentContactPropertyPropensityProfile;
  avm?: AgentContactPropertyAvmProfile;
  equity?: number;
}
