import {DashboardModuleButtonAction} from "../../dashboard-config/modules/DashboardModuleButton";
import {MediaWithType} from "../MediaWithType";
import {TabbedLPLayoutSettings} from "./TabbedLPLayoutSettings";
import {ChangelogTriggeredBy} from "../../deal";

export enum LandingPageLayoutType {
  JOURNEY = "JOURNEY",
  WEBSITE_1 = "WEBSITE_1",
  TABBED_WEBSITE_1 = "TABBED_WEBSITE_1"
}

export interface LandingPageGeneralSettings {
  mediaTitle?: string;
  mediaDescription?: string;
  trackingCode?: string;
  featuredImage?: MediaWithType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseLandingPageLayoutSettings {
  brandingId: string;
}

export interface Website1LandingPageLayoutSettings extends BaseLandingPageLayoutSettings {
  heading: string;
  subHeading: string;
  searchBarText: string;
  buttonText: string;
  action: DashboardModuleButtonAction;
  backgroundImage: MediaWithType;
  footerText?: string;
  videoEnabled?: boolean;
  videoEmbedCode?: string;
}

export interface JourneyLandingPageLayoutSettings extends BaseLandingPageLayoutSettings {
  action: DashboardModuleButtonAction;
  heading: string;
  subHeading?: string;
  searchBarText: string;
  buttonLabel: string;
}

interface LandingPageLayoutTypeToSettingsMapType {
  [LandingPageLayoutType.JOURNEY]: JourneyLandingPageLayoutSettings;
  [LandingPageLayoutType.WEBSITE_1]: Website1LandingPageLayoutSettings;
  [LandingPageLayoutType.TABBED_WEBSITE_1]: TabbedLPLayoutSettings;
}

export type AvailableLandingPageLayouts = keyof LandingPageLayoutTypeToSettingsMapType;
export type LandingPageSetting<LPLayoutType extends AvailableLandingPageLayouts> = LandingPageLayoutTypeToSettingsMapType[LPLayoutType];

export interface LandingPageConfig<LPLayoutType extends AvailableLandingPageLayouts = AvailableLandingPageLayouts> {
  archived: boolean;
  _id: string;
  layoutType: LPLayoutType;
  agencyId: string;
  agentId?: string;
  name: string;
  layoutSettings: LandingPageSetting<LPLayoutType>;
  generalSettings?: LandingPageGeneralSettings;
  createdAt: string;
  updatedAt: string;
  updatedBy: ChangelogTriggeredBy;
}
