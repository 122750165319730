export enum ContentMedium {
  EMAIL = "EMAIL",
  POSTCARD = "POSTCARD"
}

export const ContentMediumLabel: Record<ContentMedium, string> = {
  [ContentMedium.EMAIL]: "Email",
  [ContentMedium.POSTCARD]: "Postcard"
};

export const ContentMediumLabelIcon: Record<ContentMedium, string> = {
  [ContentMedium.POSTCARD]: "postcard-simple",
  [ContentMedium.EMAIL]: "envelope"
};

export const ContentMediumBudgetRequired: Record<ContentMedium, boolean> = {
  [ContentMedium.EMAIL]: false,
  [ContentMedium.POSTCARD]: true
};
