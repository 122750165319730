import {Pipe, PipeTransform} from "@angular/core";
import moment, {DurationInputArg1, DurationInputArg2, Moment, MomentInput, unitOfTime} from "moment";

@Pipe({
  name: "momentFormat",
  standalone: true
})
export class MomentFormatPipe implements PipeTransform {
  transform(input: MomentInput, format = "YYYY-MM-DD", type: "datetime" | "calendar" | "date" = "datetime", fallback = ""): string {
    let m = moment(input);
    if (!m.isValid() && fallback) {
      return fallback;
    }
    if (type === "date") {
      m = m.utcOffset(0);
    }
    if (type === "calendar") {
      return m.calendar(null, {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: format,
        nextWeek: format,
        sameElse: format
      });
    }
    return m.format(format);
  }
}

@Pipe({
  name: "unixMomentFormat",
  standalone: true
})
export class UnixMomentFormatPipe implements PipeTransform {
  static _transform(input: number, format = "YYYY-MM-DD"): string {
    return moment.unix(input).format(format);
  }

  transform(input: number, format = "YYYY-MM-DD"): string {
    return UnixMomentFormatPipe._transform(input, format);
  }
}

@Pipe({
  name: "momentDiff",
  standalone: true
})
export class MomentDiffPipe implements PipeTransform {
  transform(date1: MomentInput, date2: MomentInput, unit: unitOfTime.Diff, abs = false): number {
    const difference = moment(date1).diff(moment(date2), unit);
    return abs ? Math.abs(difference) : difference;
  }
}

@Pipe({
  name: "momentFrom",
  standalone: true
})
export class MomentFromPipe implements PipeTransform {
  transform(
    date1: MomentInput,
    date2?: MomentInput,
    options?: {suffix?: boolean; skipTransformation?: boolean; removePrefix?: boolean}
  ): string {
    const result = moment(date1).from(date2, options?.suffix);
    if (options?.removePrefix) {
      return result.replace(/^in\s/, "");
    }
    if (options?.skipTransformation) {
      return result;
    }
    return result
      .replace(/a few seconds ago|in a few seconds/gi, "Just now")
      .replace(/a |an /gi, "1 ")
      .replace(/ days| day/gi, "d")
      .replace(/ hours| hour/gi, "h")
      .replace(/ minutes| minute/gi, "m");
  }
}

@Pipe({
  name: "momentSubtract",
  standalone: true
})
export class MomentSubtractPipe implements PipeTransform {
  transform(date: MomentInput, amount: DurationInputArg1, unit: DurationInputArg2): Moment {
    return moment(date).subtract(amount, unit);
  }
}

@Pipe({
  name: "momentAdd",
  standalone: true
})
export class MomentAddPipe implements PipeTransform {
  transform(date: MomentInput, amount: DurationInputArg1, unit: DurationInputArg2): Moment {
    return moment(date).add(amount, unit);
  }
}

@Pipe({
  name: "momentIsInPast",
  standalone: true
})
export class MomentIsInPastPipe implements PipeTransform {
  transform(date: MomentInput): boolean {
    return moment(date).isBefore();
  }
}

@Pipe({
  name: "momentIsInFuture",
  standalone: true
})
export class MomentIsInFuturePipe implements PipeTransform {
  transform(date: MomentInput): boolean {
    return moment(date).isAfter();
  }
}
