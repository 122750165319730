import {Directive, Input, OnInit} from "@angular/core";
import {NgControl} from "@angular/forms";
import {takeUntil, tap} from "rxjs/operators";
import {DestroyableBase} from "../../../lib";

@Directive({
  selector: "[libInputMaxValue][formControlName], [libInputMaxValue][ngModel]",
  standalone: true
})
export class InputMaxValueDirective extends DestroyableBase implements OnInit {
  @Input("libInputMaxValue") max: number;
  @Input() libInputMaxValue!: number;

  constructor(public ngControl: NgControl) {
    super();
  }

  ngOnInit(): void {
    this.ngControl.valueChanges
      ?.pipe(
        takeUntil(this.isDestroyed),
        tap((value: number) => {
          if (value > this.max) {
            this.ngControl.control?.setValue(Math.trunc(value / 10));
          }
        })
      )
      .subscribe();
  }
}
