import {Pipe, PipeTransform} from "@angular/core";
import {
  AgentContactEmailStatusColor,
  AgentContactEnrollmentStatus,
  BackgroundTaskStatusColor,
  BillingUpdateType,
  ChargebeeStatusColor,
  ContactEngageActivityType,
  MlsStatusToColor,
  OfferStatusColor,
  PreBuiltAutomationCategoryColorMapping
} from "fello-model";
import {get} from "lodash-es";

const enumValuesToColorMapping = {
  OFFER_STATUS: OfferStatusColor,

  AGENT_CONTACT_EMAIL_STATUS: AgentContactEmailStatusColor,
  AGENT_CONTACT_ENROLLMENT_STATUS: {
    [AgentContactEnrollmentStatus.ENROLLED]: "green",
    [AgentContactEnrollmentStatus.UNSUB]: "slate-5",
    [AgentContactEnrollmentStatus.UNENROLLED]: "orange",
    [AgentContactEnrollmentStatus.PENDING]: "grey",
    [AgentContactEnrollmentStatus.NA]: "grey"
  },
  CAMPAIGN_ACTIVITY_TYPE: {
    [ContactEngageActivityType.OPENED]: "blue-light",
    [ContactEngageActivityType.CLICKED]: "blue-light",
    [ContactEngageActivityType.SCHEDULED]: "slate-1",
    [ContactEngageActivityType.SENT]: "slate-1",
    [ContactEngageActivityType.OPPORTUNITY]: "green-light",
    [ContactEngageActivityType.HOME_VALUE]: "green-light",
    [ContactEngageActivityType.FAILED]: "red"
  },
  CHARGEBEE_STATUS: ChargebeeStatusColor,
  BACKGROUND_TASK: BackgroundTaskStatusColor,
  MLS_STATUS: MlsStatusToColor,
  PRE_BUILT_AUTOMATION_CATEGORY: PreBuiltAutomationCategoryColorMapping,
  BILLING_UPDATE_TYPE: {
    [BillingUpdateType.UPDATED]: "blue-light",
    [BillingUpdateType.ADDED]: "green",
    [BillingUpdateType.REMOVED]: "red"
  }
};
export type ColorEnumType = keyof typeof enumValuesToColorMapping;

export function getEnumColor(value: string | number, enumType: ColorEnumType): string {
  return get(enumValuesToColorMapping[enumType], value) ?? value;
}

@Pipe({
  name: "enumToColor",
  standalone: true
})
export class EnumToColorPipe implements PipeTransform {
  transform(value: string | number, enumType: ColorEnumType): string {
    return getEnumColor(value, enumType);
  }
}
