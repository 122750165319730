import {
  AffordabilityCalculatorWidgetSetting,
  HomeSaleCalculatorWidgetSetting,
  MortgageCalculatorWidgetSetting,
  SearchWidgetSetting
} from "../WidgetSettings";
import {ChangelogTriggeredBy} from "../../deal";

export enum WidgetType {
  SEARCH = "SEARCH",
  HOME_SALE_CALC = "HOME_SALE_CALC",
  MORTGAGE_CALC = "MORTGAGE_CALC",
  AFFORDABILITY_CALC = "AFFORDABILITY_CALC"
}

interface WidgetTypeToSettingMap {
  [WidgetType.SEARCH]: SearchWidgetSetting;
  [WidgetType.HOME_SALE_CALC]: HomeSaleCalculatorWidgetSetting;
  [WidgetType.MORTGAGE_CALC]: MortgageCalculatorWidgetSetting;
  [WidgetType.AFFORDABILITY_CALC]: AffordabilityCalculatorWidgetSetting;
}

export type AvailableWidgetTypes = keyof WidgetTypeToSettingMap;
export type WidgetSetting<WidgetType extends AvailableWidgetTypes> = WidgetTypeToSettingMap[WidgetType];

export interface WidgetConfig<WidgetType extends AvailableWidgetTypes> {
  _id: string;
  agencyId: string;
  name: string;
  type: WidgetType;
  settings: WidgetSetting<WidgetType>;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  updatedBy: ChangelogTriggeredBy;
}
