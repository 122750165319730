<ng-container *ngIf="settings">
  <div class="wrapper">
    <div class="dialog mw-650" [libCustomTheme]="settings.theme">
      <ng-container *ngIf="currentStep === ClaimAddressSteps.Confirm_Claim">
        <ng-container *ngTemplateOutlet="confirmClaim"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentStep === ClaimAddressSteps.Address_Entry">
        <ng-container *ngTemplateOutlet="addressEntry"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentStep === ClaimAddressSteps.Address_Confirmation">
        <ng-container *ngTemplateOutlet="confirmAddress"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentStep === ClaimAddressSteps.Claim_Contact_Details">
        <ng-container *ngTemplateOutlet="claimAddressContactDetails"></ng-container>
      </ng-container>
      <ng-container *ngIf="currentStep === ClaimAddressSteps.New_Address_Contact_Details">
        <ng-container *ngTemplateOutlet="newAddressContactDetails"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #confirmClaim>
    <p class="text-size-22 fw-700 mb-2">Welcome!</p>
    <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
      <p class="text-size-16 fw-500 mb-3">Let's start by confirming your address to unlock your personalized Home Value Dashboard.</p>
      <div class="address-card">
        <ng-container *ngIf="property.propertyId | propertyExternalImg; let image; else: noImage">
          <img [src]="image" alt="img" class="img" *ngIf="image" libFallbackImage="/assets/images/svg/no_property_image.svg" />
        </ng-container>
        <ng-template #noImage>
          <img src="/assets/images/svg/no_property_image_sq.svg" class="img" alt="house" />
        </ng-template>
        <div class="w-100">
          <p class="fw-500 text-size-18">{{ property.addressComponents.doorNumber }} {{ property.addressComponents.street }}</p>
          <p class="fw-400 text-size-14">
            {{ property.addressComponents.city }}
            , {{ property.addressComponents.state }} {{ property.addressComponents.zip }}
          </p>
          <div class="line-break x-small" *ngIf="property.homeFacts"></div>
          <p class="flex-box-center text-size-12 flex-box-gap-2 flex-wrap" *ngIf="property.homeFacts; let homeFacts">
            <span
              ><span class="fw-600">{{ homeFacts.opdLite?.bedrooms || "-" }}</span> Beds
            </span>
            <span
              ><span class="fw-600">{{ homeFacts.opdLite?.fullBaths || "-" }}</span> Baths</span
            >
            <span
              ><span class="fw-600">{{ homeFacts.opdLite?.aboveGradeSqft || "-" }}</span> Sqft</span
            >
            <span
              ><span class="fw-600">{{ homeFacts.opdLite?.yearBuilt || "-" }}</span> Built</span
            >
            <span>{{ homeFacts.opdLite?.lotSizeAcres ? (homeFacts.opdLite?.lotSizeAcres | formatNumber: 2) : "-" }} Lot acres</span>
          </p>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <button (click)="currentStep = ClaimAddressSteps.Address_Entry" class="button-primary">Not Yours? Add New</button>
      <button (click)="currentStep = ClaimAddressSteps.Claim_Contact_Details">Confirm Home</button>
    </div>
  </ng-template>
  <ng-template #addressEntry>
    <p class="text-size-22 fw-700 mb-2">Add New Address</p>
    <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
      <p class="text-size-16 fw-400 mb-3">Unlock your personalized Home Value Dashboard by entering your address</p>

      <div class="form-field">
        <label class="mb-2 text-size-16 fw-600">Home Address</label>
        <lib-smarty-address-auto-complete
          [(ngModel)]="address"
          name="propertyAddress"
          id="propertyAddress"
          #propertyAddress="ngModel"
          placeholder="Search for address"
          maxlength="100"
          (ngModelChange)="addrComp = null; image = null"
          [disabled]="isLoading"
          [required]="true"
        ></lib-smarty-address-auto-complete>
      </div>
      <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{height: '45px', width: '100%', margin: '10px 0 0 '}">
      </ngx-skeleton-loader>
    </div>
    <div class="dialog-footer">
      <button (click)="validateAddress()" [disabled]="address.length <= 0 || isLoading" class="ml-auto">Continue</button>
    </div>
  </ng-template>
  <ng-template #confirmAddress>
    <p class="text-size-22 fw-700 mb-2">Welcome!</p>
    <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
      <p class="text-size-16 fw-400 mb-2 mb-4">Let's start by confirming your address to unlock your personalized Home Value Dashboard.</p>
      <div class="address-c mt-3" *ngIf="addrComp && !isLoading">
        <ng-container>
          <div class="flex-box-center">
            <img [src]="image ?? '/assets/images/svg/no_property_image.svg'" alt="house-img" class="house-img" />
            <div>
              <p class="text-size-18 fw-500">{{ addrComp.doorNumber }} {{ addrComp.street }}</p>
              <p class="text-size-14 fw-400 mt-1">{{ addrComp.city }}, {{ addrComp.state }} {{ addrComp.zip }}</p>
            </div>
          </div>
          <a class="link flex-box-center c-pointer" (click)="currentStep = ClaimAddressSteps.Address_Entry">Edit</a>
        </ng-container>
      </div>
      <ngx-skeleton-loader count="1" *ngIf="this.isLoading" [theme]="{height: '45px', width: '100%', margin: '10px 0 0'}">
      </ngx-skeleton-loader>
    </div>
    <div class="dialog-footer">
      <button (click)="addAddress()" [disabled]="address.length <= 0 || isLoading" class="ml-auto">Continue</button>
    </div>
  </ng-template>
  <ng-template #contactDetailFormTemplate>
    <p class="text-size-22 fw-700 mb-2">Last step: Confirm Your Details</p>
    <div class="overflow-auto flex-item-auto-sizing visible-scrollbar">
      <p class="text-size-16 fw-400 mb-2 mb-4">Complete your information to access to your personalized Home Value Dashboard.</p>

      <form #contactDetailForm="ngForm" class="flex-box-direction-column flex-box-gap-2">
        <div class="flex-box flex-box-direction-column flex-box-gap-2 flex-box-lg-direction-row">
          <div class="form-field">
            <label class="text-size-14 mb-1 d-block"
              >First Name <span class="fw-400" *ngIf="!hvFormSettings.firstNameMandatory">(Optional)</span></label
            >
            <input
              type="text"
              placeholder="First Name"
              [(ngModel)]="homeValueFromDashboardRequest.firstName"
              [minlength]="2"
              [maxLength]="64"
              name="firstName"
              [required]="hvFormSettings.firstNameMandatory"
              #firstNameField="ngModel"
            />
            <div *ngIf="firstNameField.invalid && firstNameField.touched" class="error-field">
              <div *ngIf="firstNameField.errors!">Enter a valid first name.</div>
            </div>
          </div>
          <div class="form-field">
            <label class="text-size-14 mb-1 d-block"
              >Last Name <span class="fw-400" *ngIf="!hvFormSettings.lastNameMandatory">(Optional)</span></label
            >
            <input
              type="text"
              placeholder="Last Name"
              [(ngModel)]="homeValueFromDashboardRequest.lastName"
              [maxLength]="64"
              [minlength]="1"
              [required]="hvFormSettings.lastNameMandatory"
              #lastNameInput="ngModel"
              name="lastName"
            />
            <div *ngIf="lastNameInput.invalid && lastNameInput.touched" class="error-field">
              <div *ngIf="lastNameInput.errors!">Enter a valid last name.</div>
            </div>
          </div>
        </div>
        <div class="flex-box flex-box-direction-column flex-box-gap-2 flex-box-lg-direction-row">
          <div class="form-field">
            <label class="text-size-14 mb-1 d-block">Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              [disabled]="!!existingEmailId"
              [(ngModel)]="homeValueFromDashboardRequest.emailId"
              required
              class="disabled-grey"
            />
          </div>
          <div class="form-field">
            <label class="text-size-14 mb-1 d-block">
              Phone <span class="fw-400" *ngIf="!hvFormSettings.phoneMandatory">(Optional)</span></label
            >
            <input
              type="tel"
              placeholder="Phone number"
              name="phone"
              libFormatPhone
              [required]="hvFormSettings.phoneMandatory"
              [pattern]="phoneNumberPattern"
              [(ngModel)]="homeValueFromDashboardRequest.phone"
              #phone="ngModel"
            />
            <div *ngIf="phone.invalid && phone.touched" class="error-field">
              <div *ngIf="phone.errors!">Enter a valid phone.</div>
            </div>
          </div>
        </div>

        <div class="flex-box-direction-row" *ngIf="hvFormSettings.includeSellingTimeline">
          <div class="form-field w-100">
            <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
            <div class="flex-box-direction-column flex-box-gap-2">
              <div class="flex-box-center">
                <input
                  type="radio"
                  id="option0"
                  class="mr-2 w-auto"
                  name="home-buy"
                  [value]="SaleTimeline.ASAP"
                  [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                  #sellingTimeline="ngModel"
                  required
                />
                <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
                  {{ SaleTimeline.ASAP | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
                </label>
              </div>
              <div class="flex-box-center">
                <input
                  type="radio"
                  id="option1"
                  class="mr-2 w-auto"
                  name="home-buy"
                  [value]="SaleTimeline.LT3M"
                  [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                  #sellingTimeline="ngModel"
                  required
                />
                <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
                  {{ SaleTimeline.LT3M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}</label
                >
              </div>
              <div class="flex-box-center">
                <input
                  type="radio"
                  id="option2"
                  class="mr-2 w-auto"
                  name="home-buy"
                  [value]="SaleTimeline.B3TO6M"
                  [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                  #sellingTimeline="ngModel"
                  required
                />
                <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
                  {{ SaleTimeline.B3TO6M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
                </label>
              </div>
              <div class="flex-box-center">
                <input
                  type="radio"
                  id="option3"
                  class="mr-2 w-auto"
                  name="home-buy"
                  [value]="SaleTimeline.B6TO12M"
                  [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                  #sellingTimeline="ngModel"
                  required
                />
                <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
                  {{ SaleTimeline.B6TO12M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
                </label>
              </div>
              <div class="flex-box-center">
                <input
                  type="radio"
                  id="option4"
                  class="mr-2 w-auto"
                  name="home-buy"
                  [value]="SaleTimeline.NA"
                  [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                  #sellingTimeline="ngModel"
                  required
                />
                <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4">{{
                  SaleTimeline.NA | enumToLabel: "FELLO_SALE_TIMELINE_SHORT"
                }}</label>
              </div>
            </div>
            <div
              *ngIf="sellingTimeline.control.touched && sellingTimeline.errors?.required"
              class="error-field text-size-12 text-color-red mt-1"
            >
              <div>Please select an option.</div>
            </div>
          </div>
        </div>
        <p class="text-size-12 text-color-light-2 mb-3" *ngIf="settings$ | async; let settings">
          <input class="consent-checkbox mr-1 w-auto" checked disabled type="checkbox" />
          <ng-container *ngIf="settings.consentText">
            <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
          </ng-container>
          <ng-container *ngIf="!settings.consentText">
            By submitting your information, you consent to receive communications from {{ settings.name }} about services, including
            marketing and informational messages, via email, phone, and text. These may be sent using automated technology, such as
            autodialed calls, pre-recorded or artificial voice messages, and text messages. You can save a copy of these terms using your
            browser’s save or print function. Providing this consent is not a condition of purchasing goods or services.
          </ng-container>
        </p>
      </form>
      <ngx-spinner [showSpinner]="isLoading" [fullScreen]="true"></ngx-spinner>
    </div>
  </ng-template>

  <ng-template #newAddressContactDetails>
    <ng-container *ngTemplateOutlet="contactDetailFormTemplate"></ng-container>
    <div class="dialog-footer">
      <button (click)="currentStep = ClaimAddressSteps.Address_Confirmation" class="button-primary">Go Back</button>
      <button
        (click)="contactDetailForm?.valid ? unClaimAndAddAddress() : contactDetailForm?.control?.markAllAsTouched(); scrollToError()"
        [disabled]="isLoading"
      >
        Continue
      </button>
    </div>
  </ng-template>
  <ng-template #claimAddressContactDetails>
    <ng-container *ngTemplateOutlet="contactDetailFormTemplate"></ng-container>
    <div class="dialog-footer">
      <button (click)="currentStep = ClaimAddressSteps.Confirm_Claim" class="button-primary">Go Back</button>
      <button (click)="contactDetailForm?.valid ? claimAddress() : contactDetailForm?.control?.markAllAsTouched(); scrollToError()">
        Continue
      </button>
    </div>
  </ng-template>
</ng-container>
